div.roleResources th.resourceCheckbox {
  font-size: 12px;
  padding: 16px 4px;
  cursor: pointer;
}

div.roleResources th.disabled {
  cursor: default;
}

div.roleResources td.resourceCheckbox, div.roleResources th.resourceCheckbox {
  text-align: center;
  word-break: normal;
}

div.roleResources td.resource {
  min-width: 150px;
}

div.roleResources .ant-table-tbody>tr>td {
  padding: 5px;
}

.create-referral .ant-row {
  margin: 0px 5px;
  flex: auto;
}
.create-referral .ant-form-item-label {
  line-height: 20px;
}

div.roleTable table tr td {
  padding: 5px 10px;
  color: #354052;
  font-size: .875rem;
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
}

div.roleTable  td.roleName {
  white-space: nowrap;
}

.hide-expand .ant-table-row-expand-icon {
  display: none;
}

div.roleResources td.ant-table-row-expand-icon-cell {
  padding: 0;
  min-width: 30px;
  width: 30px;
}

.create-referral .error {
  line-height: normal;
  margin: 0px 0px 3px;
  padding: 0px 5px 5px;
}

.create-user-group-form .ant-form-item .ant-form-item-label {
  line-height: 15px;
}

.team-role-tabs .role-tab-close-disabled + .ant-tabs-close-x {
   display: none;
}

.referral-care-plan-upload {
  display: inline-flex;
  margin-bottom: 10px;
}

.referral-care-plan-upload .ant-upload-list.ant-upload-list-file {
  display: inline-block;
}

.referral-care-plan-upload i.anticon.anticon-close {
  display: none;
}

.consent-checkbox .ant-checkbox {
  height: fit-content;
  margin-top: 5px;
}

.referral-contact-tooltip .ant-tooltip-inner {
  width: 300px;
  text-align: center;
};
